import React, { useState } from "react";
import axios from "axios";

import Wizard from "components/wizard";
import Layout from "layout";
import Loader from "components/loader";
import { t } from "intl/index";
import ThankYouForAnswering from "components/thankYouForAnswering";
import useAppStore from "stores/appStore";

const WizardContainer = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [answers, setAnswers] = useState({});
  const [apiError, setApiError] = useState(false);
  const [options] = useState(props.formData.options ?? []);
  const [showThankYou, setShowThankYou] = useState(false);

  const { tLoading, lang, questions } = useAppStore();

  if (tLoading) {
    return <Loader title={t(lang).LOADING || "Loading..."} />;
  }

  const handleNext = (values) => {
    const nextStep = activeStep + 1;
    setActiveStep(nextStep);
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questions[activeStep].id]: values,
    }));

    if (activeStep === questions.length - 1) {
      submit({
        ...answers,
        [questions[activeStep].id]: values,
      });
    }
  };

  const submit = (finalAnswers) => {
    setIsSubmiting(true);
    const { email, participantAccessToken } = props;
    const url = new URL(window.location);
    const hash = url.searchParams.get("hash");
    const surveyId = url.searchParams.get("surveyId");

    if (surveyId) {
      const transformedAnswers = Object.entries(finalAnswers)
        .map(([questionId, values]) =>
          values.map((value) => ({
            survey_id: surveyId,
            from_id: hash,
            to_id: value.value,
            question_id: questionId,
          }))
        )
        .flat();
      axios
        .post(
          `${process.env.REACT_APP_FASTIFY_API_URL}aona-edges`,
          transformedAnswers,
          {
            headers: { Authorization: `Bearer ${participantAccessToken}` },
          }
        )
        .then((res) => {
          console.log("submit res", res);
          setTimeout(() => {
            setShowThankYou(true);
            // props.onFinish();
          }, 500);
        })
        .catch((err) => props.onFinish())
        .finally(() => {
          setIsSubmiting(false);
          setShowThankYou(true);
        });
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  if (showThankYou) {
    return <ThankYouForAnswering />;
  }



  if (isSubmiting) {
    return (
      <Loader title={t(lang).SENDING_DATA || "Sending answer..."} />
    );
  }

  if (activeStep === questions.length) {
    return null;
  }

  return (
    <Layout settings={props.settings}>
      <Wizard
        onNextClick={handleNext}
        activeStep={activeStep}
        questions={questions}
        options={options}
        onBackClick={handleBack}
        settings={props.settings}
        prevAnswers={answers[questions[activeStep].id]}
      />
    </Layout>
  );
};

export default WizardContainer;