import React, { useState, useEffect } from "react";

import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import axios from "axios";
import ContactPage from "components/ContactPage";
import Loader from "components/loader";
import ThankYouForAnswering from "components/thankYouForAnswering";
import WelcomeContainer from "containers/Welcome";
import WizardContainer from "containers/Wizard";
import jwt from "jsonwebtoken";
import useAppStore from "stores/appStore";
import { storeLangSettings } from "utils/language";

const getTheme = ({ primaryColor, secondaryColor }) => {
  return createMuiTheme({
    palette: {
      primary: { main: primaryColor },
      secondary: { main: secondaryColor },
    },
  });
};

const getParams = () => {
  const url = new URL(window.location);
  const hash = url.searchParams.get("hash");
  const email = url.searchParams.get("email");
  // NEW PARAMS
  const surveyId = url.searchParams.get("surveyId");

  return {
    hash,
    email,
    surveyId,
  };
};

const generateAccessToken = (hash) => {
  const payload = JSON.stringify({ participantId: hash });
  const token = jwt.sign(
    { data: payload },
    process.env.REACT_APP_PLATFORM_JWT_SECRET,
    {
      expiresIn: process.env.REACT_APP_JWT_EXPIRES_IN,
    },
  );
  return token;
};

const App = () => {
  const [isWelcomePage, setWelcomePage] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [formData, setFormData] = useState(null);
  const [userAlreadyAnswered, setUserAlreadyAnswered] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const { hash, email, surveyId } = getParams();
  const {
    lang,
    setLang,
    setAccessToken,
    accessToken: storeToken,
    setTranslatedQuestions,
    setOriginalQuestions,
  } = useAppStore();
  const participantAccessToken = generateAccessToken(hash);

  // init app store vars
  useEffect(() => {
    if (!storeToken) {
      setAccessToken(participantAccessToken);
    }
  }, [participantAccessToken]);

  // Geolocation useEffect
  useEffect(() => {
    const fetchGeolocation = async () => {
      try {
        const response = await fetch("https://ipapi.co/json/");
        if (!response || !response.ok) {
          const userLocale = Intl.DateTimeFormat()
            .resolvedOptions()
            .locale.split("-")[0];
          setLang(userLocale);
          storeLangSettings(userLocale);
          return;
        }
        const data = await response.json();
        const countryCode = data.country_code.toLowerCase();
        const languageMap = {
          us: "en",
          gb: "en",
          au: "en",
          nz: "en",
          br: "pt",
          pt: "pt",
          it: "it",
          es: "es",
          mx: "es",
          ca: "en",
          ar: "es",
          cl: "es",
          co: "es",
          pe: "es",
          ec: "es",
          uy: "es",
          py: "es",
          bo: "es",
          jp: "ja",
          // German-speaking countries
          // de: "de",
          // at: "de",
          // ch: "de",
          // li: "de",
          // lu: "de",
          // be: "de",

          // // French-speaking countries
          // fr: "fr",
          // be: "fr",
          // bf: "fr",
          // bi: "fr",
          // bj: "fr",
          // cd: "fr",
          // cf: "fr",
          // cg: "fr",
          // ci: "fr",
          // cm: "fr",
          // dj: "fr",
          // dz: "fr",
          // ga: "fr",
          // gf: "fr",
          // gn: "fr",
          // gp: "fr",
          // ht: "fr",
          // km: "fr",
          // mg: "fr",
          // ml: "fr",
          // mq: "fr",
          // nc: "fr",
          // ne: "fr",
          // pf: "fr",
          // rw: "fr",
          // sc: "fr",
          // sn: "fr",
          // td: "fr",
          // tg: "fr",
          // tn: "fr",
          // vu: "fr",
          // wf: "fr",
          // // Chinese-speaking countries
          // cn: "zh",
          // hk: "zh",
          // mo: "zh",
          // sg: "zh",
          // tw: "zh",
        };
        const detectedLanguage = languageMap[countryCode] || "en";
        storeLangSettings(detectedLanguage);
        setLang(detectedLanguage);
      } catch (error) {
        console.error("Error fetching geolocation:", error);
        const userLocale = Intl.DateTimeFormat()
          .resolvedOptions()
          .locale.split("-")[0];
        storeLangSettings(userLocale);
        setLang(userLocale);
      }
    };

    fetchGeolocation();
  }, []);

  // Survey Data useEffect
  useEffect(() => {
    if (!surveyId) return;

    const fetchSurveyData = async () => {
      let version;
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_FASTIFY_API_URL}/v1/version`,
        );
        version = data.version;
      } catch (error) {
        console.error(`Error fetching version: ${error.message}`);
      }
      setLoading(true);
      try {
        const specialId = process.env.REACT_APP_TEST_USER_ID;
        if (hash === specialId) {
          axios
            .get(
              `${process.env.REACT_APP_FASTIFY_API_URL}/${version}/surveys/${surveyId}`,
              {
                headers: { Authorization: `Bearer ${participantAccessToken}` },
              },
            )
            .then((response) => {
              const data = response.data;
              const transformedData = {
                questions: data.survey.settings.questions.map((question) => ({
                  id: question.id,
                  title: question.name,
                  name: question.content,
                  options: [],
                  type: "multiSelect",
                })),
                settings: {
                  primaryColor: data.survey.settings.primaryColor,
                  secondaryColor: data.survey.settings.secondaryColor,
                  logoBase64: data.survey.settings.logoBase64,
                },
                welcomePageString: data.survey.settings.welcomePageHtml,
                welcomePageString2: data.survey.settings.privacyPageHtml,
              };

              console.log("data: ", transformedData);

              setOriginalQuestions(transformedData.questions);
              setFormData(transformedData);
            })
            .catch((error) => {
              setUserAlreadyAnswered(true);
              setFormData([]);
            });
        } else {
          axios
            .get(
              `${process.env.REACT_APP_FASTIFY_API_URL}/${version}/surveys/${surveyId}/participant/${hash}`,
              {
                headers: { Authorization: `Bearer ${participantAccessToken}` },
              },
            )
            .then((response) => {
              const data = response.data;
              const transformedData = {
                questions: data.survey.settings.questions.map((question) => ({
                  id: question.id,
                  title: question.name,
                  name: question.content,
                  options: [],
                  type: "multiSelect",
                })),
                settings: {
                  primaryColor: data.survey.settings.primaryColor,
                  secondaryColor: data.survey.settings.secondaryColor,
                  logoBase64: data.survey.settings.logoBase64,
                },
                welcomePageString: data.survey.settings.welcomePageHtml,
                welcomePageString2: data.survey.settings.privacyPageHtml,
              };

              console.log("data: ", transformedData);

              setOriginalQuestions(transformedData.questions);
              setFormData(transformedData);
            })
            .catch((error) => {
              setUserAlreadyAnswered(true);
              setFormData([]);
            });
        }
      } catch (error) {
        const statusCode = error?.response?.status;
        if (statusCode === 409) {
          setUserAlreadyAnswered(true);
          setFormData([]);
        } else {
          setFormData(null);
        }
      }
    };

    fetchSurveyData();
  }, [surveyId]);

  // when FormData and lang are ready
  useEffect(() => {
    if (formData && lang) {
      setTranslatedQuestions(formData.questions, lang).then(() => {
        console.log("App.js setTranslatedQuestions done");
      });
      setLoading(false);
    }
  }, [formData, lang]);

  if (isLoading) return <Loader />;

  if (!formData) return <ContactPage />;

  if (userAlreadyAnswered) {
    return <ThankYouForAnswering />;
  }

  if (isWelcomePage)
    return (
      <ThemeProvider theme={getTheme(formData?.settings)}>
        <WelcomeContainer
          onNextClick={() => setWelcomePage(false)}
          welcomePageString={formData?.welcomePageString}
          welcomePageString2={formData?.welcomePageString2}
          settings={formData?.settings}
        />
      </ThemeProvider>
    );

  return (
    <ThemeProvider theme={getTheme(formData.settings)}>
      <WizardContainer
        formData={formData}
        email={email}
        settings={formData?.settings}
        onFinish={() => setReloadPage(!reloadPage)}
        participantAccessToken={participantAccessToken}
      />
    </ThemeProvider>
  );
};

export default App;
